// import React from "react"
// import { Link } from "gatsby"
// import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// // import service1 from "../../images/home/service-1.webp"
// import service2 from "../../images/home/service-2.webp"

// const OurCapabilities = () => {
//     const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3, // Adjust the number of slides to show
//     slidesToScroll: 1,
//   };
//   return (
//     <div class="section-full bg-capabilities content-inner pt-3 ">
//       <div class="container" style={{ maxWidth: "90%" }}>
//         <div className="section-head text-center">
//           <h2 className="title mt-4">Our Products</h2>
//         </div>
//         <div class="row pb-5">
//           <div
//             class="col-xl-3 col-md-6 col-sm-12 wow bounceInUp"
//             data-wow-duration="2s"
//             data-wow-delay="0.6s"
//           >
//             <div class="blog-post blog-grid blog-rounded blog-effect1">
//               <div
//                 class="dlab-post-media dlab-post-media-home"
//                 style={{ borderRadius: "20px 20px 0 0" }}
//               >
//                 <Link to="/products-and-services/services/diagnostics">
//                   <StaticImage
//                     src="../../images/products/hpv.png"
//                     layout="fullWidth"
//                     height={250}
//                     width={400}
//                     style={{ borderRadius: "20px 20px 0 0" }}
//                   />
//                 </Link>
//               </div>
//               <div class="dlab-info p-a25 border-1 info-capabilities">
//                 <div class="dlab-post-title d-flex align-items-center">
//                   <h4 class="post-title">
//                     <Link to="/products/hpv-xpress-matrix">
//                       HPV XpressMatrix™ Kit
//                     </Link>
//                   </h4>
//                 </div>
//                 <div class="dlab-post-text">
//                   <p className="text-justify">
//                     HPV XpressMatrix™ is an In Vitro Diagnostic (IVD) test kit,
//                     based on the use of target amplification and hybridization
//                     methods for the detection of the following 21 HPV genotypes:
//                   </p>
//                   <p className="text-justify">
//                     <span className="font-weight-bold">High Risk:</span>
//                     <br />
//                     HPV 16, 18, 31, 33, 35, 39, 45, 51, 52, 53, 56, 58, 59, 66,
//                     68
//                   </p>
//                   <p className="text-justify">
//                     <span className="font-weight-bold">Low Risk:</span>
//                     <br />
//                     HPV 6, 11, 42, 43, 44, 81
//                   </p>
//                 </div>
//                 <div class="dlab-post-readmore">
//                   <Link
//                     to="/products/hpv-xpress-matrix"
//                     title="MORE INFO"
//                     rel="bookmark"
//                     class="site-button btnhover20"
//                   >
//                     MORE INFO
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div
//             class="col-xl-3 col-md-6 col-sm-12 wow bounceInUp"
//             data-wow-duration="2s"
//             data-wow-delay="0.6s"
//           >
//             <div class="blog-post blog-grid blog-rounded blog-effect1">
//               <div
//                 class="dlab-post-media dlab-post-media-home"
//                 style={{ borderRadius: "20px 20px 0 0" }}
//               >
//                 <Link to="/products/sars-cov-2-real-time">
//                   <StaticImage
//                     src="../../images/products/elva-pcr.png"
//                     layout="fullWidth"
//                     height={250}
//                     width={400}
//                     style={{ borderRadius: "20px 20px 0 0" }}
//                   />
//                 </Link>
//               </div>
//               <div class="dlab-info p-a25 border-1 info-capabilities">
//                 <div class="dlab-post-title d-flex align-items-center">
//                   <h4 class="post-title">
//                     <Link to="/products/sars-cov-2-real-time">
//                       Elva Diagnostic SARS-CoV-2 Real-time RT PCR Kit
//                     </Link>
//                   </h4>
//                 </div>
//                 <div class="dlab-post-text">
//                   <p className="text-justify">
//                     Elva Diagnostic SARS-CoV-2 Real-time RT-PCR Kit is a
//                     real-time RT-PCR in vitro diagnostic test intended for the
//                     qualitative detection of nucleic acid from the SARS-CoV-2
//                     virus in human oropharyngeal swab and nasopharyngeal swab
//                     specimens .
//                   </p>
//                 </div>
//                 <div class="dlab-post-readmore">
//                   <Link
//                     to="/products/sars-cov-2-real-time"
//                     title="MORE INFO"
//                     rel="bookmark"
//                     class="site-button btnhover20"
//                   >
//                     MORE INFO
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div
//             class="col-xl-3 col-md-6 col-sm-12 wow bounceInUp"
//             data-wow-duration="2s"
//             data-wow-delay="0.6s"
//           >
//             <div class="blog-post blog-grid blog-rounded blog-effect1">
//               <div
//                 class="dlab-post-media dlab-post-media-home"
//                 style={{ borderRadius: "20px 20px 0 0" }}
//               >
//                 <Link to="/products/sars-cov-2-saliva-nucleic">
//                   <StaticImage
//                     src="../../images/products/elva-saliva.png"
//                     layout="fullWidth"
//                     height={250}
//                     width={400}
//                     style={{ borderRadius: "20px 20px 0 0" }}
//                   />
//                 </Link>
//               </div>
//               <div class="dlab-info p-a25 border-1 info-capabilities">
//                 <div class="dlab-post-title d-flex align-items-center">
//                   <h4 class="post-title">
//                     <Link to="/products/sars-cov-2-saliva-nucleic">
//                       Elva Diagnostic SARS-CoV-2 Saliva Nucleic Acid Test Kit
//                     </Link>
//                   </h4>
//                 </div>
//                 <div class="dlab-post-text">
//                   <p className="text-justify">
//                     Elva Diagnostic SARS-CoV-2 Saliva Nucleic Acid Test Kit is a
//                     reverse-transcription loop-mediated isothermal amplification
//                     (RT-LAMP)-based in vitro diagnostic assay intended for the
//                     qualitative colorimetric detection of SARS-CoV-2 virus
//                     nucleic acid in the saliva specimen.
//                   </p>
//                 </div>
//                 <div class="dlab-post-readmore">
//                   <Link
//                     to="/products/sars-cov-2-saliva-nucleic"
//                     title="MORE INFO"
//                     rel="bookmark"
//                     class="site-button btnhover20"
//                   >
//                     MORE INFO
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div
//             class="col-xl-3 col-md-6 col-sm-12 wow bounceInUp"
//             data-wow-duration="2s"
//             data-wow-delay="0.6s"
//           >
//             <div class="blog-post blog-grid blog-rounded blog-effect1">
//               <div
//                 class="dlab-post-media dlab-post-media-home"
//                 style={{ borderRadius: "20px 20px 0 0" }}
//               >
//                 <Link to="/products/ampfire-hpv-screening">
//                   <StaticImage
//                     src="../../images/products/atila.png"
//                     layout="fullWidth"
//                     height={250}
//                     width={400}
//                     style={{ borderRadius: "20px 20px 0 0" }}
//                   />
//                 </Link>
//               </div>
//               <div class="dlab-info p-a25 border-1 info-capabilities">
//                 <div class="dlab-post-title d-flex align-items-center">
//                   <h4 class="post-title">
//                     <Link to="/products/ampfire-hpv-screening">
//                       AmpFire HPV Screening HR 16/18 Kit
//                     </Link>
//                   </h4>
//                 </div>
//                 <div class="dlab-post-text">
//                   <p className="text-justify">
//                     AmpFire HPV Screening 16/18/HR is an in vitro diagnostic kit
//                     (IVD) for isothermal-based nucleic acid amplification with a
//                     real time fluorescence detection method to detect the
//                     presence of DNA from the Human papillomavirus (HPV) virus
//                     genotypes 16, 18, 31, 33, 35, 39 , 45, 51, 52, 53, 56, 58,
//                     59, 66, 68 qualitative, and specific identification of HPV16
//                     and HPV18 subtypes from various types of samples.
//                   </p>
//                 </div>
//                 <div class="dlab-post-readmore">
//                   <Link
//                     to="/products/ampfire-hpv-screening"
//                     title="MORE INFO"
//                     rel="bookmark"
//                     class="site-button btnhover20"
//                   >
//                     MORE INFO
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default OurCapabilities

import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const OurCapabilities = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 4000,
    cssEase: "linear",
  }

  return (
    <div className="container">
      <div className="section-head text-center">
        <h2 className="title mt-4">Our Products</h2>
      </div>
      <Slider {...settings}>
        <div className="slider-item">
          <div
            class="wow bounceInUp"
            data-wow-duration="2s"
            data-wow-delay="0.6s"
          >
            <div class="blog-post blog-grid blog-rounded blog-effect1 mr-3">
              <div
                class="dlab-post-media dlab-post-media-home"
                style={{ borderRadius: "20px 20px 0 0" }}
              >
                <Link to="/products/indigen-real-time">
                  <StaticImage
                    src="../../images/products/indigen.png"
                    layout="fullWidth"
                    height={250}
                    width={400}
                    style={{ borderRadius: "20px 20px 0 0" }}
                  />
                </Link>
              </div>
              <div class="dlab-info p-a25 border-1 info-capabilities">
                <div class="dlab-post-title d-flex align-items-center">
                  <h4 class="post-title">
                    <Link to="/products/indigen-real-time">
                      INDIGEN MTB/NTM/DR-TB Real Time PCR Kit Gen. 2
                    </Link>
                  </h4>
                </div>
                <div class="dlab-post-text">
                  <p className="text-justify">
                    INDIGEN MTB/NTM/DR-TB Real Time PCR Kit Gen. 2 is an
                    in-vitro diagnostic intended for the qualitative detection
                    of Mycobacterium tuberculosis (MTB), Nontuberculous
                    mycobacteria (NTM), Drug Resistance Rifampicin and Isoniazid
                    by using real time PCR in TB suspected individuals, from
                    sputum specimen. INDIGEN MTB/NTM/DR-TB Real Time PCR kit is
                    an open system in real time PCR machine which has FAM, HEX,
                    ROX, and CY5 channels.
                  </p>
                </div>
                <div class="dlab-post-readmore">
                  <Link
                    to="/products/hpv-xpress-matrix"
                    title="MORE INFO"
                    rel="bookmark"
                    class="site-button btnhover20"
                  >
                    MORE INFO
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="slider-item">
          <div
            class="wow bounceInUp"
            data-wow-duration="2s"
            data-wow-delay="0.6s"
          >
            <div class="blog-post blog-grid blog-rounded blog-effect1 mr-3">
              <div
                class="dlab-post-media dlab-post-media-home"
                style={{ borderRadius: "20px 20px 0 0" }}
              >
                <Link to="/products/diago-t790m">
                  <StaticImage
                    src="../../images/boxDiago.png"
                    layout="fullWidth"
                    height={250}
                    width={400}
                    style={{ borderRadius: "20px 20px 0 0" }}
                  />
                </Link>
              </div>
              <div class="dlab-info p-a25 border-1 info-capabilities">
                <div class="dlab-post-title d-flex align-items-center">
                  <h4 class="post-title">
                    <Link to="/products/diago-t790m">
                      DIAGO T790M Mutation Detection Kit
                    </Link>
                  </h4>
                </div>
                <div class="dlab-post-text">
                  <p className="text-justify">
                    DIAGO T790M Mutation Detection Kit is an in-vitro diagnostic
                    intended for the qualitative detection of T790M mutation by
                    using real time PCR in patients who have received first-line
                    TKI with non-invasive blood samples (Cell Free DNA). The
                    DIAGO T790M Mutation Detection Kit can be used for open
                    system PCR with a minimum of 2 channels (FAM & CY5).
                  </p>
                </div>
                <div class="dlab-post-readmore">
                  <Link
                    to="/products/hpv-xpress-matrix"
                    title="MORE INFO"
                    rel="bookmark"
                    class="site-button btnhover20"
                  >
                    MORE INFO
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="slider-item">
          <div
            class="wow bounceInUp"
            data-wow-duration="2s"
            data-wow-delay="0.6s"
          >
            <div class="blog-post blog-grid blog-rounded blog-effect1 mr-3">
              <div
                class="dlab-post-media dlab-post-media-home"
                style={{ borderRadius: "20px 20px 0 0" }}
              >
                <Link to="/products/sars-cov-2-real-time">
                  <StaticImage
                    src="../../images/products/elva-pcr.png"
                    layout="fullWidth"
                    height={250}
                    width={400}
                    style={{ borderRadius: "20px 20px 0 0" }}
                  />
                </Link>
              </div>
              <div class="dlab-info p-a25 border-1 info-capabilities">
                <div class="dlab-post-title d-flex align-items-center">
                  <h4 class="post-title">
                    <Link to="/products/sars-cov-2-real-time">
                      Elva Diagnostic SARS-CoV-2 Real-time RT PCR Kit
                    </Link>
                  </h4>
                </div>
                <div class="dlab-post-text">
                  <p className="text-justify">
                    Elva Diagnostic SARS-CoV-2 Real-time RT-PCR Kit is a
                    real-time RT-PCR in vitro diagnostic test intended for the
                    qualitative detection of nucleic acid from the SARS-CoV-2
                    virus in human oropharyngeal swab and nasopharyngeal swab
                    specimens .
                  </p>
                </div>
                <div class="dlab-post-readmore">
                  <Link
                    to="/products/sars-cov-2-real-time"
                    title="MORE INFO"
                    rel="bookmark"
                    class="site-button btnhover20"
                  >
                    MORE INFO
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="slider-item">
          <div
            class="wow bounceInUp"
            data-wow-duration="2s"
            data-wow-delay="0.6s"
          >
            <div class="blog-post blog-grid blog-rounded blog-effect1 mr-3">
              <div
                class="dlab-post-media dlab-post-media-home"
                style={{ borderRadius: "20px 20px 0 0" }}
              >
                <Link to="/products/ampfire-hpv-screening">
                  <StaticImage
                    src="../../images/products/atila.png"
                    layout="fullWidth"
                    height={250}
                    width={400}
                    style={{ borderRadius: "20px 20px 0 0" }}
                  />
                </Link>
              </div>
              <div class="dlab-info p-a25 border-1 info-capabilities">
                <div class="dlab-post-title d-flex align-items-center">
                  <h4 class="post-title">
                    <Link to="/products/ampfire-hpv-screening">
                      AmpFire HPV Screening HR 16/18 Kit
                    </Link>
                  </h4>
                </div>
                <div class="dlab-post-text">
                  <p className="text-justify">
                    AmpFire HPV Screening 16/18/HR is an in vitro diagnostic kit
                    (IVD) for isothermal-based nucleic acid amplification with a
                    real time fluorescence detection method to detect the
                    presence of DNA from the Human papillomavirus (HPV) virus
                    genotypes 16, 18, 31, 33, 35, 39 , 45, 51, 52, 53, 56, 58,
                    59, 66, 68 qualitative, and specific identification of HPV16
                    and HPV18 subtypes from various types of samples.
                  </p>
                </div>
                <div class="dlab-post-readmore">
                  <Link
                    to="/products/ampfire-hpv-screening"
                    title="MORE INFO"
                    rel="bookmark"
                    class="site-button btnhover20"
                  >
                    MORE INFO
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="slider-item">
          <div
            class="wow bounceInUp"
            data-wow-duration="2s"
            data-wow-delay="0.6s"
          >
            <div class="blog-post blog-grid blog-rounded blog-effect1 mr-3">
              <div
                class="dlab-post-media dlab-post-media-home"
                style={{ borderRadius: "20px 20px 0 0" }}
              >
                <Link to="/products-and-services/services/diagnostics">
                  <StaticImage
                    src="../../images/products/hpv.png"
                    layout="fullWidth"
                    height={250}
                    width={400}
                    style={{ borderRadius: "20px 20px 0 0" }}
                  />
                </Link>
              </div>
              <div class="dlab-info p-a25 border-1 info-capabilities">
                <div class="dlab-post-title d-flex align-items-center">
                  <h4 class="post-title">
                    <Link to="/products/hpv-xpress-matrix">
                      HPV XpressMatrix™ Kit
                    </Link>
                  </h4>
                </div>
                <div class="dlab-post-text">
                  <p className="text-justify">
                    HPV XpressMatrix™ is an In Vitro Diagnostic (IVD) test kit,
                    based on the use of target amplification and hybridization
                    methods for the detection of the following 21 HPV genotypes:
                  </p>
                  <p className="text-justify">
                    <span className="font-weight-bold">High Risk:</span>
                    <br />
                    HPV 16, 18, 31, 33, 35, 39, 45, 51, 52, 53, 56, 58, 59, 66,
                    68
                  </p>
                  <p className="text-justify">
                    <span className="font-weight-bold">Low Risk:</span>
                    <br />
                    HPV 6, 11, 42, 43, 44, 81
                  </p>
                </div>
                <div class="dlab-post-readmore">
                  <Link
                    to="/products/hpv-xpress-matrix"
                    title="MORE INFO"
                    rel="bookmark"
                    class="site-button btnhover20"
                  >
                    MORE INFO
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  )
}

export default OurCapabilities
