import React, { Component, useState } from "react"
import { Link } from "gatsby"
// import Header from "../layouts/header"
import Header from "../layouts/header4"
import Footer from "../layouts/footer4"
import { blogContent3 } from "../components/SliderContent"
import { Parallax } from "react-parallax"
import FloatingBtn from "../components/custom-components/floatingBtn"
import HomeTestimonial from "../components/custom-components/HomeTestimonial"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import OurCapabilities from "../components/custom-components/OurCapabilities"
import ScrollToTop from "../components/scrollToTop"
import CareerTestimonial from "./../components/custom-components/careerTestimonial"
import HomeSlider from "../components/homeslider1"
import ClientSlider1 from "../components/clientslider1"
import BannerBlankNew1 from "../images/banner/BannerBlankNew1.png"
import BannerBlankNew2 from "../images/banner/BannerBlankNew2.png"
import AboutUs1 from "../images/about/aboutus1.png"
import AboutUs2 from "../images/about/aboutus2.png"
import visimisi from "../images/goals/visimisi.png"

import "../css/plugins.css"
import "../css/style.css"
import "../css/skin/skin-1.css"
import "../css/templete.min.css"
import "../css/custom-css/hanif-main.css"
import "../css/custom-css/custom-index.css"
import "../css/custom-css/hanif-main.css"

import bg4 from "../images/landing-page-banner.webp"

import ourCapabilities1 from "../images/our-work/pic1.jpg"
import ourCapabilities2 from "../images/our-work/pic2.jpg"
import ourCapabilities3 from "../images/our-work/pic3.jpg"
import NewsCard from "../components/custom-components/LastNewsCard"
import NewsListLayout from "../layouts/news-event-template"
import { StaticQuery, graphql } from "gatsby"
import ProductsSlider from "../components/custom-components/ProductsSlider"
import SEO from "../components/seo"

import HomeBanner from "../components/custom-components/HomeBanner"
import { HomeBannerSliderContent } from "../components/SliderContent"

const Index = ({ data }) => {
  const homeSliderContent4 = [
    {
      image: BannerBlankNew1,
      title: "Your Reliable Partner in Molecular Diagnostic Kit",
      description:
        "An in-Vitro Diagnostics Manufacturing and service company. Established since 2012, focusing on molecular diagnostics for oncology and infectious disease.",
    },
    {
      image: BannerBlankNew2,
      title: "Your Reliable Partner in Molecular Diagnostic Kit",
      description:
        "An in-Vitro Diagnostics Manufacturing and service company. Established since 2012, focusing on molecular diagnostics for oncology and infectious disease.",
    },
  ]

  return (
    <div className="skin-1 index">
      <SEO title="Home" />
      <Header indexPage />
      <HomeSlider
        data={homeSliderContent4}
        contentWrapperCls="sliderStyle4"
        btnCls="site-button"
        btnText="ABOUT US"
        btn2Text="OUR SERVICES"
        btnLink="#About-Us"
        btn2Link="#Our-Services"
        show2Btn
      />

      <div
        class="section-full call-action style1 wow fadeIn"
        data-wow-duration="2s"
        data-wow-delay="0.2s"
      >
        <div class="container" style={{ maxWidth: "90%" }}>
          <div className="item" id="About-Us">
            <div class="row align-items-center">
              <div class="col-lg-6 col-md-12 m-b30">
                <div class="our-story">
                  <h2
                    class="title"
                    style={{
                      fontSize: "50px",
                      fontWeight: "400",
                      lineHeight: "70px",
                      marginBottom: "20px",
                    }}
                  >
                    About Us
                  </h2>
                  <p style={{ fontSize: "16px" }} className="text-justify">
                    PT. KalGen DNA was established in 2012, is a joint venture
                    between PT Bifarma Adiluhung (a subsidiary of PT Kalbe Farma
                    Tbk.) and a Malaysian company, DNA Laboratories Sdn. Bhd.,
                    which is a leading provider of genetic screening and
                    molecular diagnostic services and products in Malaysia.
                  </p>
                  <p style={{ fontSize: "16px" }} className="text-justify">
                    Our commitment to quality and improvement are demostrated by
                    well-trained molecular analyst, certification by WHO LabNet
                    proficiency test, ISO 13485, obtained CPAKB, CDAKB permit
                    from Ministry of Health. We will continue to develop
                    innovative diagnostic kits in the area of personalized
                    healthcare to improve human health and well-being.
                  </p>
                  <Link to={"#Vision-Mission"} class="site-button btnhover16">
                    Read More
                  </Link>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 m-b30 text-center">
                <img src={AboutUs1} class="radius-sm" alt="" />
              </div>
              <div class="col-lg-3 col-md-6 m-b30 text-center">
                <img src={AboutUs2} class="radius-sm" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section-full box-about-list" id="Vision-Mission">
        <div class="row spno">
          <div class="col-lg-6 col-md-12">
            <img src={visimisi} alt="" class="img-cover" />
          </div>
          <div class="col-lg-6 col-md-12 bg-primary">
            <div class="max-w700 m-auto p-tb50 p-lr20">
              <div class="text-white">
                <h2>
                  We are Certified Manufacturing Facility to Ensure Quality &
                  Safety
                </h2>
              </div>
              <div class="icon-bx-wraper m-b30 left">
                <div class="icon-md"></div>
                <div class="icon-content">
                  <h4 class="dlab-tilte pb-3">Mission</h4>
                  <p>
                    To Improve Human Health and Well Being through Innovative
                    Diagnostic Kits.
                  </p>
                </div>
              </div>
              <div class="icon-bx-wraper m-b30 left">
                <div class="icon-md"></div>
                <div class="icon-content">
                  <h4 class="dlab-tilte pb-3">Vision</h4>
                  <p>
                    To be The Most Reliable Partner of High Quality Diagnostic
                    Kits, driven by Innovation and Excellent Management
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="section-full content-inner bg-gray wow fadeIn"
        data-wow-duration="2s"
        data-wow-delay="0.6s"
        id="Our-Services"
      >
        <div class="container">
          <div class="section-head text-black">
            <h2 class="title text-center">Our Services</h2>
            <p class="text-justify" style={{ fontSize: "16px" }}>
              A well-proven way to prevent cervical cancer is to have screening
              test. Cervical cancer can affect anyone with a cervix who has ever
              been sexually active.
            </p>
            <p class="text-justify" style={{ fontSize: "16px" }}>
              KalGen DNA as a marketing for cervical cancer screening test,
              cooperate with collaborator laboratory, encourage anyone with a
              cervix who has been sexually active to be screened for cervical
              cancer.
            </p>
            <p class="text-justify" style={{ fontSize: "16px" }}>
              The fact that cervical cancer rarely presents any symptoms in its
              early stages highlights the importance of regular screening for
              the disease.
            </p>
            <p
              className="text-center"
              style={{ fontSize: "16px", fontWeight: "bold" }}
            >
              The main goal of cervical screening is to detect and treat
              precancer before cancer develops.
            </p>
          </div>
          <div class="row pb-4">
            <div class="col-lg-3 col-md-6 col-sm-12 my-2 text-center">
              <Link to={"/services/hpv-genotyping"}>
                <StaticImage
                  src="../images/services/hpv-genotyping.png"
                  layout="constrained"
                  style={{ borderRadius: "20px" }}
                />
              </Link>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 my-2 text-center">
              <Link to={"/services/hpv-highrisk"}>
                <StaticImage
                  src="../images/services/hpv-highrisk.png"
                  layout="constrained"
                  style={{ borderRadius: "20px" }}
                />
              </Link>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 my-2 text-center">
              <Link to={"/services/liquid-based-cytology"}>
                <StaticImage
                  src="../images/services/liquid-cytology.png"
                  layout="constrained"
                  style={{ borderRadius: "20px" }}
                />
              </Link>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 my-2 text-center">
              <Link to={"/services/co-testing"}>
                <StaticImage
                  src="../images/services/co-testing.png"
                  layout="constrained"
                  style={{ borderRadius: "20px" }}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>


      <div id="Our-Products">
        <OurCapabilities />
      </div>
      

      <div
        class="section-full content-inner-2 bg-gray wow fadeIn "
        data-wow-duration="2s"
        data-wow-delay="0.6s"
        id="Partnership"
      >
        <div class="container">
          <div class="section-head text-center CTA-title">
            <h2 class="title text-capitalize">
              We work with our partners to bring future of healthcare
            </h2>
            <h2 className="title text-capitalize" style={{ marginTop: "10px" }}>
              <span className="CTA-span">
                <Link to="/contact-us" className="partnership-btn">
                  join with our partnership
                </Link>
              </span>
            </h2>
          </div>
          <ClientSlider1 />
        </div>
      </div>


      <Footer hideContactInfo id="Contact-Us" />
    </div>
  )
}

export default Index
